import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from '../../../util/router';
import { renderMUILoadingButton } from '../../../util/util';

const ModalAddVendor = ({ show, handleClose, handleSave, vendors = [] }) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {}
  });

  const [text, setText] = useState('');

  useEffect(() => {
    reset({});
    return () => {
      setText('');
    };
  }, [show]);

  const onSubmit = async (data) => {
    setLoading(true);
    await handleSave(data);
    setLoading(false);
  };

  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>Add Vendor</Modal.Header>
          <Modal.Body>
            <Box sx={{ marginBottom: 2 }}>
              <Form.Group>
                <Form.Control
                  placeholder="Search"
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                />
              </Form.Group>
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              {vendors
                .filter((vendor) => {
                  if (text.trim()) {
                    return (
                      vendor.companyName.toLowerCase().includes(text.trim().toLowerCase()) ||
                      vendor.department.toLowerCase().includes(text.trim().toLowerCase()) ||
                      vendor.contactName.toLowerCase().includes(text.trim().toLowerCase())
                    );
                  } else {
                    return true;
                  }
                })
                .map((item) => (
                  <Form.Group key={`${item.id}-${Math.random() * 1000}`}>
                    <Form.Check
                      label={`${item.companyName} - ${item.department} - ${item.contactName}`}
                      {...register(item.id)}
                    />
                  </Form.Group>
                ))}
            </Box>
            <Form.Group>
              <Button component={Link} to="/inventory/settings/vendors">
                + Add new vendor
              </Button>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit'
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalAddVendor;
