import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { documentColumns, instrumentEventColumns, vendorColumns } from './Columns';
import ModalAddVendor from '../Modal/ModalAddVendor';
import { Link, useHistory, useParams } from '../../../util/router';
import {
  deleteInventoryDocuments,
  deleteInventoryEquipments,
  deleteInventoryEvents,
  setInventoryDocuments,
  setInventoryEquipments,
  useGetCompanies,
  useGetPersonnel,
  useInventoryDocuments,
  useInventoryEquipmentDetails,
  useInventoryEvents,
  useInventorySettings,
  useInventoryVendors
} from '../../../util/db';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import ModalFormInstrument from '../Modal/ModalFormInstrument';
import { alphaNumericSorter, formatCurrency } from '../../../util/util';
import moment from 'moment';
import ModalFormDocument from '../Modal/ModalFormDocument';
import { deleteFileAsync, updateMetadata, uploadFileAsync } from '../../../util/storage';

const InstrumentDetails = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: personnel = [] } = useGetPersonnel();
  const { data: events = [] } = useInventoryEvents({ instrumentName: id });
  const { data: rawEquipmentDetails = [] } = useInventoryEquipmentDetails(id);
  const { data: vendors = [] } = useInventoryVendors();
  const { data: documents = [] } = useInventoryDocuments();
  const { data: companies } = useGetCompanies();
  const { data: inventorySettingsInstrumentStatus = [] } = useInventorySettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: inventorySettingsDocumentType = [] } = useInventorySettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const [instrumentDetails, setInstrumentDetails] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isOpenAddDocument, setOpenAddDocument] = useState(false);
  const [isOpenAddVendor, setOpenAddVendor] = useState(false);
  const [editingDocument, setEditingDocument] = useState(null);

  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      const updatedLocationsPersonnel = [];
      companies.forEach((company) => {
        const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;
        const locationToPush = {
          name: currentLocation,
          id: currentLocation,
          value: currentLocation,
          label: currentLocation
        };
        const locationExists = updatedLocationsPersonnel.find(
          (location) => location.name === currentLocation
        );
        if (!locationExists) {
          updatedLocationsPersonnel.push(locationToPush);
        }
      });
      setAllLocations(updatedLocationsPersonnel);
    }
  }, [companies]);

  useEffect(() => {
    if (rawEquipmentDetails && rawEquipmentDetails.length) {
      setInstrumentDetails(rawEquipmentDetails[0]);
    }
  }, [rawEquipmentDetails]);

  const status = inventorySettingsInstrumentStatus.find(
    (item) => item.id === instrumentDetails?.status
  );

  const handleSave = async (data) => {
    await setInventoryEquipments({ ...instrumentDetails, ...data });
    setOpen(false);
  };

  const handleDelete = async () => {
    if (
      confirm(
        events.length > 0
          ? 'Events are associated with this instrument. Are you sure you want to delete it?'
          : 'Are you sure to delete this equipment?'
      )
    ) {
      await deleteInventoryEquipments(instrumentDetails);
      history.push('/inventory/instruments');
    }
  };

  const handleAddVendors = async (data) => {
    const selectedVendors = Object.keys(data).filter((vendorId) => data[vendorId]);
    await setInventoryEquipments({
      ...instrumentDetails,
      vendors: [...(instrumentDetails.vendors || []), ...selectedVendors]
    });
    setOpenAddVendor(false);
  };

  const handleDeleteVendor = async (data) => {
    const remainingVendors = instrumentDetails.vendors.filter((vendor) => vendor !== data.id);
    await setInventoryEquipments({
      ...instrumentDetails,
      vendors: remainingVendors
    });
  };

  const handleDeleteDocument = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      if (data.attachment && data.attachment.fullPath) {
        await deleteFileAsync(data.attachment.fullPath);
      }
      await deleteInventoryDocuments(data);
    }
  };

  const handleDeleteEvent = async (eventDetails) => {
    if (confirm('Are you sure to delete this event?')) {
      await deleteInventoryEvents(eventDetails);
    }
  };

  const getEventsStatus = () => {
    const today = moment();
    const eventDates = events.map((event) => ({
      dateStarted: event.dateStarted ? moment(event.dateStarted.seconds * 1000) : '',
      dateDue: moment(event.dateDue.seconds * 1000)
    }));
    if (eventDates.find((event) => event.dateDue.isBefore(today))) {
      return 'Past Due';
    } else if (
      eventDates.find(
        (event) =>
          event.dateDue.isSameOrAfter(today) &&
          event.dateStarted &&
          event.dateStarted.isBefore(today)
      )
    ) {
      return 'In Progress';
    }
    return 'Active';
  };

  const handleAddDocuments = async (data) => {
    const { attachment, ...document } = { ...data, instrumentId: id };

    await setInventoryDocuments(document);

    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `inventory-management/${id}/${document.id}/${attachment[0].name}`,
        document
      );
    } else {
      document.attachment = attachment;
    }

    await setInventoryDocuments(document);

    setOpenAddDocument(false);
  };

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpenAddDocument(true);
  };

  return (
    <div className="event-details">
      <div className="event-header">
        <h2>Instrument Details</h2>
        <div className="actions">
          <Button variant="contained" onClick={() => setOpen(true)}>
            Edit
          </Button>
          <ModalFormInstrument
            show={isOpen}
            handleClose={() => setOpen(false)}
            handleSave={handleSave}
            data={instrumentDetails}
            allLocations={allLocations}
            vendors={vendors}
            inventorySettingsInstrumentStatus={inventorySettingsInstrumentStatus}
          />
          <Button variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
      <div className="event-information">
        <h3>Instrument Information</h3>
        <table>
          <tbody>
            <tr>
              <td>Instrument Number</td>
              <td>{instrumentDetails?.name}</td>
              <td>Location</td>
              <td>{instrumentDetails?.location}</td>
            </tr>
            <tr>
              <td>Parameter</td>
              <td>{instrumentDetails?.parameter}</td>
              <td>Status</td>
              <td>{status?.name || 'Undefined'}</td>
            </tr>
            <tr>
              <td>Manufacturer</td>
              <td>{instrumentDetails?.manufacturer}</td>
              <td>Model</td>
              <td>{instrumentDetails?.model}</td>
            </tr>
            <tr>
              <td>Serial Number</td>
              <td>{instrumentDetails?.serialNumber}</td>
              <td>Event Status</td>
              <td>{getEventsStatus()}</td>
            </tr>
            <tr>
              <td>Internal ID</td>
              <td>{instrumentDetails?.internalId}</td>
              <td>Cost</td>
              <td>{formatCurrency(instrumentDetails?.cost)}</td>
            </tr>
          </tbody>
        </table>

        <h3>Events</h3>
        <div className="table-box">
          <CustomTable
            data={events
              .filter((event) => {
                return (
                  event.status !== 'In progress' ||
                  (event.dateDue.seconds * 1000 >= moment().startOf('day').valueOf() &&
                    event.status !== 'Complete')
                );
              })
              .sort((a, b) => a.dateDue.seconds - b.dateDue.seconds)}
            header={instrumentEventColumns({
              instrumentName: instrumentDetails?.name,
              persons: personnel
            })}
            deleteRowData={handleDeleteEvent}
            numberOfRows={10}
            action={['delete']}
            sort={{ sorting: { sortModel: [{ field: 'eventNumber', sort: 'asc' }] } }}
          />
          <div className="button-below">
            <Button
              variant="contained"
              component={Link}
              sx={{ color: 'white !important' }}
              to={`/inventory/events?addInstrumentEvent=${instrumentDetails?.id}`}
            >
              Add event
            </Button>
          </div>
        </div>
        <h3>Notes</h3>
        <div>
          <Form.Group className="mb-3">
            <Form.Control as="textarea" rows={3} value={instrumentDetails?.notes} readOnly={true} />
          </Form.Group>
        </div>
        <h3>Documents</h3>
        <div className="table-box">
          <CustomTable
            data={documents.filter((document) => document.instrumentId === id && !document.eventId)}
            header={documentColumns}
            deleteRowData={handleDeleteDocument}
            viewRowData={(data) => showFormDocument(data)}
            numberOfRows={10}
            action={['view', 'delete']}
            sort={{ sorting: { sortModel: [{ field: 'documentId', sort: 'asc' }] } }}
          />
          <div className="button-below">
            <Button variant="contained" onClick={() => showFormDocument(null)}>
              Add document
            </Button>
            <ModalFormDocument
              show={isOpenAddDocument}
              handleClose={() => setOpenAddDocument(false)}
              handleSave={handleAddDocuments}
              data={editingDocument}
              inventorySettingsDocumentType={inventorySettingsDocumentType}
            />
          </div>
        </div>

        <h3>Vendors</h3>
        <div className="table-box">
          <CustomTable
            data={vendors.filter((vendor) =>
              (instrumentDetails?.vendors || []).includes(vendor.id)
            )}
            header={vendorColumns}
            deleteRowData={handleDeleteVendor}
            numberOfRows={10}
            action={['delete']}
            sort={{ sorting: { sortModel: [{ field: 'companyName', sort: 'asc' }] } }}
          />
          <div className="button-below">
            <Button variant="contained" onClick={() => setOpenAddVendor(true)}>
              Add vendor
            </Button>
            <ModalAddVendor
              show={isOpenAddVendor}
              handleClose={() => setOpenAddVendor(false)}
              handleSave={handleAddVendors}
              vendors={vendors
                .filter((vendor) => !(instrumentDetails?.vendors || []).includes(vendor.id))
                .map((vendor) => ({ ...vendor, name: vendor.companyName }))
                .sort(alphaNumericSorter)}
            />
          </div>
        </div>
        <div className="button-below text-center">
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default InstrumentDetails;
