import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { listEventColumnsFn } from './Columns';
import {
  setInventoryEvents,
  useGetCompanies,
  useGetPersonnel,
  useInventoryEquipments,
  useInventoryEvents,
  useInventorySettings,
  useInventoryVendors
} from '../../../util/db';
import { Link, useHistory, useLocation } from '../../../util/router';
import ModalFormEvent from '../Modal/ModalFormEvent';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import qs from 'qs';
import { alphaNumericSorter } from '../../../util/util';

const TabEvents = () => {
  const history = useHistory();
  const location = useLocation();
  const [filterModel, setFilterModel] = useState({});
  const { data: personnel } = useGetPersonnel();
  const { data: events = [] } = useInventoryEvents(filterModel);
  const { data: companies } = useGetCompanies();
  const { data: instruments = [] } = useInventoryEquipments();
  const { data: vendors = [] } = useInventoryVendors();
  const { data: inventorySettingsEventStatus = [] } = useInventorySettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: inventorySettingsEventType = [] } = useInventorySettings(SETTING_TYPE.EVENT_TYPE);
  const [allLocations, setAllLocations] = useState([]);
  const [instrumentName, setInstrumentName] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [instrumentFilters, setInstrumentFilters] = useState();

  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      const updatedLocationsPersonnel = [];
      companies.forEach((company) => {
        const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;
        const locationToPush = {
          name: currentLocation,
          id: currentLocation,
          value: currentLocation,
          label: currentLocation
        };
        const locationExists = updatedLocationsPersonnel.find(
          (location) => location.name === currentLocation
        );
        if (!locationExists) {
          updatedLocationsPersonnel.push(locationToPush);
        }
      });
      setAllLocations(updatedLocationsPersonnel);
    }
  }, [companies]);

  // add Event from Instrument Details
  useEffect(() => {
    if (location?.search) {
      const qsObj = qs.parse(String(location?.search).replace(/\?/g, ''));
      if (qsObj?.addInstrumentEvent) {
        setOpen(true);
        setInstrumentName(qsObj?.addInstrumentEvent);
      }
    }
  }, [location]);

  const handleSave = async (data) => {
    // get vendors from instrument when create
    if (!data.id) {
      const selectedIntrument = instruments.find((row) => row.id === data.instrumentName);
      await setInventoryEvents({ ...data, vendors: selectedIntrument?.vendors || [] });
    } else {
      await setInventoryEvents(data);
    }

    setOpen(false);
    if (instrumentName) {
      history.push(`/inventory/instruments/${instrumentName}`);
      setInstrumentName('');
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (instrumentName) {
      history.push('/inventory/events');
      setInstrumentName('');
    }
  };

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  const handleLocationChange = (field, event) => {
    if (event.target.value) {
      const instrumentIds = instruments
        .filter((i) => i.location == event.target.value)
        .map((i) => i.id);
      setInstrumentFilters(instrumentIds);
    } else {
      setInstrumentFilters(undefined);
    }
  };

  return (
    <>
      <div className="top-actions">
        <Button variant="contained" onClick={() => setOpen(true)}>
          Create new event
        </Button>
        <ModalFormEvent
          show={isOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          personnel={personnel}
          inventorySettingsEventStatus={inventorySettingsEventStatus}
          inventorySettingsEventType={inventorySettingsEventType}
          instruments={instruments.sort(alphaNumericSorter)}
          instrumentName={instrumentName}
        />
        <Button
          variant="contained"
          component={Link}
          sx={{ color: 'white !important' }}
          to="/inventory/events/at-a-glance"
        >
          At a glance
        </Button>
      </div>
      <div className="filter">
        <p>
          <strong>Filter data by:</strong>
        </p>
        <div>
          <Row>
            <Form.Group as={Col} controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Select onChange={(event) => handleLocationChange('location', event)}>
                <option value="">All locations</option>
                {allLocations.map((option) => (
                  <option key={option.name} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="days">
              <Form.Label>Date</Form.Label>
              <Form.Select onChange={(event) => handleChange('date', event)}>
                <option value="">All days</option>
                <option value="30">Last 30 days</option>
                <option value="60">Last 60 days</option>
                <option value="90">Last 90 days</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Select onChange={(event) => handleChange('status', event)}>
                <option value="">All status</option>
                {inventorySettingsEventStatus.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="eventType">
              <Form.Label>Event Type</Form.Label>
              <Form.Select onChange={(event) => handleChange('eventType', event)}>
                <option value="">All types</option>
                {inventorySettingsEventType.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}></Form.Group>
            <Form.Group as={Col} controlId="search">
              <Form.Label>Search</Form.Label>
              <Form.Control
                onChange={(event) => setSearchValue(event.target.value)}
                placeholder="All fields"
              />
            </Form.Group>
          </Row>
        </div>
      </div>
      <div className="grid-view">
        <h2>Events</h2>
        <CustomTable
          numberOfRows={20}
          data={events
            .map((row) => {
              const dataInstrument = instruments.find((item) => item.id === row.instrumentName);
              const dataStatus = inventorySettingsEventStatus.find(
                (item) => item.id === row.status
              );
              const eventType = inventorySettingsEventType.find(
                (item) => item.id === row.eventType
              );
              return {
                ...row,
                location: dataInstrument?.location,
                instrumentId: dataInstrument?.id,
                instrumentName: dataInstrument?.name,
                status: dataStatus?.name,
                eventType: eventType?.name
              };
            })
            .filter((row) => {
              if (searchValue) {
                return (
                  row.eventNumber.toString().includes(searchValue) ||
                  row.location.toLowerCase().includes(searchValue.toLowerCase()) ||
                  row.instrumentName.toLowerCase().includes(searchValue.toLowerCase())
                );
              } else {
                return true;
              }
            })
            .filter((row) => {
              if (!instrumentFilters) {
                return true;
              }
              return instrumentFilters.includes(row.instrumentId);
            })}
          header={listEventColumnsFn({ vendors, personnel })}
          sort={{ sorting: { sortModel: [{ field: 'eventNumber', sort: 'asc' }] } }}
          rowHeight="auto"
          sx={{
            '.MuiDataGrid-cell': {
              minHeight: '52px !important'
            },
            '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
              display: 'none'
            }
          }}
        />
      </div>
    </>
  );
};

export default TabEvents;
