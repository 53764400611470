import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { settingDocumentColumns } from './Columns';
import {
  deleteInventoryDocuments,
  setInventoryDocuments,
  setInventoryEquipments,
  useGetCompanies,
  useInventoryDocuments,
  useInventoryEquipments,
  useInventorySettings
} from '../../../util/db';
import { SETTING_TYPE } from './DefineCategories';
import { Button } from '@mui/material';
import ModalFormDocument from '../Modal/ModalFormDocument';
import { uploadFileAsync } from '../../../util/storage';
import { alphaNumericSorter } from '../../../util/util';

const DocumentList = () => {
  const [filterModel, setFilterModel] = useState({});
  const { data: instruments = [] } = useInventoryEquipments();
  const { data: documents = [] } = useInventoryDocuments(filterModel);
  const { data: companies } = useGetCompanies();
  const { data: inventorySettingsDocumentType = [] } = useInventorySettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const [allLocations, setAllLocations] = useState([]);
  const [editingDocument, setEditingDocument] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [locationFilter, setLocationFilter] = useState();

  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      const updatedLocationsPersonnel = [];
      companies.forEach((company) => {
        const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;
        const locationToPush = {
          name: currentLocation,
          id: currentLocation,
          value: currentLocation,
          label: currentLocation
        };
        const locationExists = updatedLocationsPersonnel.find(
          (location) => location.name === currentLocation
        );
        if (!locationExists) {
          updatedLocationsPersonnel.push(locationToPush);
        }
      });
      setAllLocations(updatedLocationsPersonnel);
    }
  }, [companies]);

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpen(true);
  };

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  const handleDelete = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      await deleteInventoryDocuments(data);
    }
  };

  const handleSave = async (data) => {
    const { attachment, ...document } = data;

    await setInventoryDocuments(document);

    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `inventory-management/${document.instrumentId}/${document.id}/${attachment[0].name}`,
        document
      );
    }
    await setInventoryDocuments(document);

    setOpen(false);
  };

  return (
    <>
      <div className="filter">
        <p>
          <strong>Filter documents by:</strong>
        </p>
        <div>
          <Row>
            <Form.Group as={Col} controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Select onChange={(event) => setLocationFilter(event.target.value)}>
                <option value="">All locations</option>
                {allLocations.map((option) => (
                  <option key={option.name} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="documentType">
              <Form.Label>Document Type</Form.Label>
              <Form.Select onChange={(event) => handleChange('documentType', event)}>
                <option value="">All types</option>
                {inventorySettingsDocumentType.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="instrument">
              <Form.Label>Instrument</Form.Label>
              <Form.Select onChange={(event) => handleChange('instrumentId', event)}>
                <option value="">All Instruments</option>
                {instruments.sort(alphaNumericSorter).map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
        </div>
      </div>
      <div className="grid-view">
        <div className="inner-actions">
          <h2>Documents</h2>
          <Button variant="contained" onClick={() => showFormDocument(null)}>
            Add new
          </Button>
          <ModalFormDocument
            show={isOpen}
            handleClose={() => setOpen(false)}
            handleSave={handleSave}
            data={editingDocument}
            inventorySettingsDocumentType={inventorySettingsDocumentType}
            instruments={instruments}
          />
        </div>
        <CustomTable
          numberOfRows={20}
          data={documents
            .map((row) => {
              const dataInstrument = instruments.find((item) => item.id === row.instrumentId);
              return {
                ...row,
                location: dataInstrument?.location,
                instrumentName: dataInstrument?.name
              };
            })
            .filter((d) => {
              if (!locationFilter) {
                return true;
              } else {
                const instrumentIds = instruments
                  .filter((i) => i.location === locationFilter)
                  .map((i) => i.id);
                return instrumentIds.includes(d.instrumentId);
              }
            })}
          header={settingDocumentColumns({
            documentTypes: inventorySettingsDocumentType,
            actionEditDocument: showFormDocument
          })}
          deleteRowData={handleDelete}
          action={['delete']}
          sort={{ sorting: { sortModel: [{ field: 'documentName', sort: 'asc' }] } }}
          rowHeight="auto"
          sx={{
            '.MuiDataGrid-cell': {
              minHeight: '52px !important'
            },
            '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
              display: 'none'
            }
          }}
        />
      </div>
    </>
  );
};

export default DocumentList;
