import { Box, Button, CircularProgress } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { deleteFileAsync, getMetadataAsync } from '../../../util/storage';
import { renderMUILoadingButton } from '../../../util/util';
const ModalFormDocument = ({
  show,
  handleClose,
  handleSave,
  data,
  inventorySettingsDocumentType,
  instruments,
  disabled = false
}) => {
  const [prevAttachment, setPrevAttachment] = useState();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, reset, getValues } = useForm({
    defaultValues: {
      id: data?.id || '',
      documentName: data?.documentName || '',
      documentType: data?.documentType || '',
      description: data?.description || '',
      instrumentId: data?.instrumentId || '',
      attachment: data?.attachment || ''
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      documentName: data?.documentName || '',
      documentType: data?.documentType || '',
      description: data?.description || '',
      instrumentId: data?.instrumentId || '',
      attachment: data?.attachment || ''
    });

    setPrevAttachment(data?.attachment);
  }, [show]);

  const hasFile = getValues('attachment')?.length > 0;

  const onSubmit = async (data) => {
    setLoading(true);
    if (data.attachment?.length > 0 && prevAttachment) {
      await deleteFileAsync(prevAttachment.filePath);
    }
    await handleSave(data);
    setLoading(false);
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? 'Edit Document' : 'Add Document'}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Document Name</Form.Label>
                <Form.Control
                  name="documentName"
                  {...register('documentName', { required: true })}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Document Type</Form.Label>
                <Form.Select name="documentType" {...register('documentType', { required: true })}>
                  <option value="">-- Select --</option>
                  {inventorySettingsDocumentType.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              {instruments ? (
                <Form.Group as={Col}>
                  <Form.Label>Instrument</Form.Label>
                  <Form.Select
                    name="instrumentId"
                    {...register('instrumentId', { required: true })}
                  >
                    <option value="">-- Select --</option>
                    {instruments.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                <></>
              )}
              <Form.Group as={Col}>
                <Form.Label>Attachment</Form.Label>
                <Form.Control
                  name="attachment"
                  type="file"
                  {...register('attachment', { required: !data?.attachment })}
                />
                <Box display="flex" alignItems="center" gap={1}>
                  {!hasFile && prevAttachment ? (
                    <>
                      <a target="_blank" href={prevAttachment?.downloadUrl}>
                        {prevAttachment?.fileName}
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  {...register('description', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit',
              disabled: disabled
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormDocument;
