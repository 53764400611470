import moment from 'moment';
import { Link } from '../../../util/router';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { ArticleOutlined, Delete, Edit } from '@mui/icons-material';
import { Box } from '@mui/material';

export const dayTimeComparator = (v1, v2) => {
  const d1 = new Date(v1).getTime();
  const d2 = new Date(v2).getTime();
  return d1 - d2;
};

export const numberComparator = (v1, v2) => {
  const d1 = Number(v1);
  const d2 = Number(v2);
  return d1 - d2;
};

const maxRepeatTimeByMonth = 18;
export const maxRepeatTime = {
  Monthly: maxRepeatTimeByMonth,
  Annually: Math.floor(maxRepeatTimeByMonth / 12)
};

export const recurringEventOptions = [
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' }
];

export const EventStatus = {
  IN_PROGRESS: 'In Progress',
  PAST_DUE: 'Past Due'
};

export const listEventColumnsFn = ({ vendors = [], personnel = [] }) => [
  {
    field: 'eventNumber',
    headerName: 'ID',
    minWidth: 150,
    flex: 1,
    sortComparator: numberComparator,
    renderCell: (params) => {
      return <Link to={`/inventory/events/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 300,
    flex: 1
  },
  {
    field: 'instrumentName',
    headerName: 'Instrument Name',
    minWidth: 180,
    flex: 1,
    renderCell: (params) => {
      return params.value ? (
        <Link to={`/inventory/instruments/${params.row.instrumentId}`}>{params.value}</Link>
      ) : (
        ''
      );
    }
  },
  {
    field: 'dateDue',
    headerName: 'Date Due',
    sortComparator: dayTimeComparator,
    width: 150,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    flex: 1
  },
  {
    field: 'eventType',
    headerName: 'Event Type',
    minWidth: 120,
    flex: 1
  },
  {
    field: 'responsibleParty',
    headerName: 'Responsible Party',
    minWidth: 120,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <div className="comments">
          {personnel
            .filter((person) => (params.value || []).includes(person.id))
            .map((person, index) => (
              <p key={index}>{person.name}</p>
            ))}
        </div>
      );
    }
  },
  {
    field: 'vendor',
    headerName: 'Vendor',
    minWidth: 180,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <div className="comments">
          {vendors
            .filter((vendor) => (params.row.vendors || []).includes(vendor.id))
            .map((vendor, index) => (
              <p key={index}>{vendor.companyName}</p>
            ))}
        </div>
      );
    }
  }
];

export const documentColumns = (handleViewClick, handleDeleteClick) => [
  {
    field: 'documentName',
    headerName: 'Name',
    flex: 2
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 4
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 80,
    cellClassName: 'actions',
    renderCell: (params) => {
      return (
        <>
          <GridActionsCellItem
            icon={<ArticleOutlined />}
            label="View"
            className="textPrimary"
            onClick={() => handleViewClick(params.row)}
            color="inherit"
          />
          <GridActionsCellItem
            icon={<Delete />}
            disabled={!params.row.eventId}
            label="Delete"
            className="textPrimary"
            onClick={() => handleDeleteClick(params.row)}
            color="inherit"
          />
        </>
      );
    }
  }
];

export const vendorColumns = [
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 2
  },
  {
    field: 'contactName',
    headerName: 'Contact',
    flex: 2
  },
  {
    field: 'contactEmail',
    headerName: 'Email',
    flex: 2
  },
  {
    field: 'contactPhone',
    headerName: 'Phone',
    flex: 2
  }
];

export const notificationColumns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 2
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 2
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: 2
  }
];

export const eventColumns = [
  {
    field: 'location',
    headerName: 'Location',
    flex: 1,
    minWidth: 120
  },
  {
    field: 'instrumentName',
    headerName: 'Instrument Name',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      return params.value ? (
        <Link to={`/inventory/instruments/${params.row.instrumentId}`}>{params.value}</Link>
      ) : (
        ''
      );
    }
  },
  {
    field: 'eventType',
    headerName: 'Event Type',
    flex: 1,
    minWidth: 200
  },
  {
    field: 'dateDue',
    headerName: 'Date Due',
    sortComparator: dayTimeComparator,
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'recurringEvent',
    headerName: 'Next Event',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      if (params.value === 'Yes') {
        const nextEventDate = moment(params.row.dateDue.seconds * 1000).add(
          params.row.recurringIn,
          'days'
        );
        return nextEventDate.format('MM/DD/YYYY');
      } else {
        return 'N';
      }
    }
  }
];
