import React, { useState } from 'react';
import CSVReaderComponent from '../../CSVParser/CSVParser';
import moment from 'moment';

import {
  setInventoryEquipments,
  setInventoryEvents,
  useGetPersonnel,
  useInventorySettings
} from '../../../util/db';
import { SETTING_TYPE } from './DefineCategories';
import ModalError from '../../Common/modal/ModalError';
import { dateFormat } from '../../../util/util';
import { Alert } from 'react-bootstrap';

const ImportInstruments = () => {
  const { data: personnel } = useGetPersonnel();
  const { data: inventorySettingsEventType = [] } = useInventorySettings(SETTING_TYPE.EVENT_TYPE);
  const { data: inventorySettingsEventStatus = [] } = useInventorySettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: inventorySettingsInstrumentStatus = [] } = useInventorySettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );

  const [showError, setShowError] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const readCSVData = (results) => {
    return results
      .map((instrument) => {
        if (
          instrument[getFieldIndex('Location')] &&
          instrument[getFieldIndex('Location')] !== 'Location'
        ) {
          return {
            location: instrument[getFieldIndex('Location')],
            name: instrument[getFieldIndex('Instrument Name')],
            parameter: instrument[getFieldIndex('Parameter')],
            serialNumber: instrument[getFieldIndex('Serial Number')],
            manufacturer: instrument[getFieldIndex('Manufacturer')],
            model: instrument[getFieldIndex('Model')],
            notes: instrument[getFieldIndex('Notes')],
            internalId: instrument[getFieldIndex('Internal ID')],
            status: instrument[getFieldIndex('Status')],
            cost: instrument[getFieldIndex('Cost')],
            events: [
              {
                instrumentName: instrument[getFieldIndex('Instrument Name')],
                eventType: instrument[getFieldIndex('Event Type 1')],
                description: instrument[getFieldIndex('Description 1')],
                responsibleParty: instrument[getFieldIndex('Responsible Party 1')],
                status: instrument[getFieldIndex('Status 1')],
                dateDue: instrument[getFieldIndex('Date Due 1')],
                cost: instrument[getFieldIndex('Cost 1')],
                notificationLeadTime: instrument[getFieldIndex('Notification Lead Time (days) 1')],
                recurringEvent: instrument[getFieldIndex('Recurring Event 1')],
                recurringIn: instrument[getFieldIndex('Recurring In (days) 1')],
                notes: instrument[getFieldIndex('Notes 1')]
              },
              {
                instrumentName: instrument[getFieldIndex('Instrument Name')],
                eventType: instrument[getFieldIndex('Event Type 2')],
                description: instrument[getFieldIndex('Description 2')],
                responsibleParty: instrument[getFieldIndex('Responsible Party 2')],
                status: instrument[getFieldIndex('Status 2')],
                dateDue: instrument[getFieldIndex('Date Due 2')],
                cost: instrument[getFieldIndex('Cost 2')],
                notificationLeadTime: instrument[getFieldIndex('Notification Lead Time (days) 2')],
                recurringEvent: instrument[getFieldIndex('Recurring Event 2')],
                recurringIn: instrument[getFieldIndex('Recurring In (days) 2')],
                notes: instrument[getFieldIndex('Notes 2')]
              }
            ]
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const getFieldIndex = (title) => {
    const csvHeader = [
      'Location',
      'Instrument Name',
      'Parameter',
      'Serial Number',
      'Manufacturer',
      'Model',
      'Notes',
      'Internal ID',
      'Status',
      'Cost',
      'Event Type 1',
      'Description 1',
      'Responsible Party 1',
      'Status 1',
      'Date Due 1',
      'Cost 1',
      'Notification Lead Time (days) 1',
      'Recurring Event 1',
      'Recurring In (days) 1',
      'Notes 1',
      'Event Type 2',
      'Description 2',
      'Responsible Party 2',
      'Status 2',
      'Date Due 2',
      'Cost 2',
      'Notification Lead Time (days) 2',
      'Recurring Event 2',
      'Recurring In (days) 2',
      'Notes 2'
    ];

    return csvHeader.indexOf(title);
  };

  const handleResults = async (results) => {
    setLoading(true);
    const instruments = readCSVData(results);

    const errorMessages = instruments.flatMap((instrument) => validateData(instrument));

    if (errorMessages.length !== 0) {
      setErrorData({
        messages: errorMessages,
        textButton: 'Ok'
      });
      setShowError(true);
      setLoading(false);
      return;
    }

    await Promise.all(
      instruments.map(async (instrument) => {
        await insertInstrument(instrument);
      })
    );

    setShow(true);
    setLoading(false);
  };

  const insertInstrument = async (instrument) => {
    const { events, ...instrumentField } = instrument;

    const statusIndex = inventorySettingsInstrumentStatus.findIndex(
      (s) => s.name == instrumentField.status
    );

    const newInstrument = {
      ...instrumentField,
      status: inventorySettingsInstrumentStatus[statusIndex].id
    };

    await setInventoryEquipments(newInstrument);

    await insertEvents(newInstrument, events);
  };

  const insertEvents = async (newInstrument, events) => {
    await Promise.all(
      events.map(async (event) => {
        if (!(event.status && event.responsibleParty && event.eventType && event.dateDue)) {
          return;
        }
        const statusIndex = inventorySettingsEventStatus.findIndex((s) => s.name == event.status);
        const responsiblePartyIndex = personnel.findIndex((s) => s.name == event.responsibleParty);
        const eventTypeIndex = inventorySettingsEventType.findIndex(
          (s) => s.name == event.eventType
        );

        const newEvent = {
          ...event,
          instrumentName: newInstrument.id,
          status: inventorySettingsEventStatus[statusIndex].id,
          responsibleParty: personnel[responsiblePartyIndex].id,
          eventType: inventorySettingsEventType[eventTypeIndex].id,
          dateDue: moment(event.dateDue, dateFormat(event.dateDue)).toDate()
        };

        await setInventoryEvents(newEvent);
      })
    );
  };

  const validateData = (instrument) => {
    const { events, ...instrumentField } = instrument;

    const instrumentErrorMessages = isValidInstrument(instrumentField);

    const eventErrorMessages = events.flatMap((event) => isValidEvent(event));

    if (instrumentErrorMessages.length > 0 || eventErrorMessages.length > 0) {
      const errorMessages = instrumentErrorMessages.concat(eventErrorMessages);

      errorMessages.unshift(`-----------> ${instrument.location} - ${instrument.name}`);

      return errorMessages;
    }

    return [];
  };

  const invalidEnumError = (fieldName, fieldValue) => `${fieldName} "${fieldValue}" invalid`;

  const mustHaveValueError = (fieldName) => `${fieldName} is must have value`;

  const mustBeNumberError = (fieldName, fieldValue) =>
    `${fieldName} "${fieldValue}" must be number`;

  const mustBeDateError = (fieldName, fieldValue) => `${fieldName} "${fieldValue}" must be date`;

  const validateField = (
    fieldName,
    fieldValue,
    isRequired = false,
    isNumber = false,
    isDate = false,
    isInList = false,
    listData = []
  ) => {
    const errorMessages = [];

    if (isRequired && !fieldValue) {
      errorMessages.push(mustHaveValueError(fieldName));
      return errorMessages;
    }

    if (fieldValue && isNumber && isNaN(fieldValue)) {
      errorMessages.push(mustBeNumberError(fieldName, fieldValue));
    }

    if (fieldValue && isDate && !moment(fieldValue, dateFormat(fieldValue), true).isValid()) {
      errorMessages.push(mustBeDateError(fieldName, fieldValue));
    }

    if (fieldValue && isInList && !listData.includes(fieldValue)) {
      errorMessages.push(invalidEnumError(fieldName, fieldValue));
    }

    return errorMessages;
  };

  const isValidInstrument = (instrument) => {
    return [
      ...validateField('Location', instrument.location, true),
      ...validateField('Name', instrument.name, true),
      ...validateField('Serial Number', instrument.serialNumber, true),
      ...validateField('Manufacturer', instrument.manufacturer, true),
      ...validateField('Model', instrument.model, true),
      ...validateField('InternalId', instrument.internalId, true),
      ...validateField(
        'Status',
        instrument.status,
        true,
        false,
        false,
        true,
        inventorySettingsInstrumentStatus.map((s) => s.name)
      ),
      ...validateField('Cost', instrument.cost, true, true)
    ];
  };

  const isValidEvent = (event) => {
    console.log(event);

    //  Case Empty Event
    if (
      event.cost === '' &&
      event.description === '' &&
      event.dateDue === '' &&
      event.eventType === '' &&
      event.notes === '' &&
      event.notificationLeadTime === '' &&
      event.recurringEvent === '' &&
      event.recurringIn === '' &&
      event.responsibleParty === '' &&
      event.status === ''
    ) {
      return [];
    }
    return [
      ...validateField('Instrument Name', event.instrumentName, true),
      ...validateField(
        'Event Type',
        event.eventType,
        true,
        false,
        false,
        true,
        inventorySettingsEventType.map((ev) => ev.name)
      ),
      ...validateField('Description', event.description, true),
      ...validateField(
        'Responsible Party',
        event.responsibleParty,
        true,
        false,
        false,
        true,
        personnel.map((p) => p.name)
      ),
      ...validateField(
        'Status',
        event.status,
        true,
        false,
        false,
        true,
        inventorySettingsEventStatus.map((s) => s.name)
      ),
      ...validateField('Date Due', event.dateDue, true, false, true),
      ...validateField('Cost', event.cost, true, true),
      ...validateField('Notification Lead Time', event.notificationLeadTime, true, true),
      ...validateField('Recurring Event', event.recurringEvent, true, false, false, true, [
        'Yes',
        'No',
        'Y',
        'N'
      ]),
      ...(event.recurringEvent === 'Yes' || event.recurringEvent === 'Y'
        ? validateField('Recurring In', event.recurringIn, true, true)
        : [])
    ];
  };

  return (
    <>
      <ModalError
        show={showError}
        setShow={setShowError}
        title="Import Instrument Error"
        messages={errorData.messages}
        textButton={errorData.textButton}
      />
      <Alert key="success" show={show} dismissible onClose={() => setShow(false)} variant="success">
        Successfully imported instruments
      </Alert>
      <CSVReaderComponent
        config={{ encoding: 'ISO-8859-1' }}
        handleResults={handleResults}
        loading={loading}
      />
    </>
  );
};

export default ImportInstruments;
