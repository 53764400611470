import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { assiciatedEventColumns, assiciatedInstrumentColumns, VendorStatus } from './Columns';
import { useHistory, useParams } from '../../../util/router';
import {
  deleteInventoryVendors,
  setInventoryEquipments,
  setInventoryEvents,
  setInventoryVendors,
  useInventoryEquipments,
  useInventoryEvents,
  useInventorySettings,
  useInventoryVendorDetails
} from '../../../util/db';
import ModalFormVendor from '../Modal/ModalFormVendor';
import { SETTING_TYPE } from './DefineCategories';

const VendorDetails = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data: rawVendorDetails } = useInventoryVendorDetails(id);
  const { data: instruments = [] } = useInventoryEquipments({ vendorId: id });
  const { data: allInstruments = [] } = useInventoryEquipments();
  const { data: events = [] } = useInventoryEvents({ vendorId: id });
  const { data: inventorySettingsInstrumentStatus = [] } = useInventorySettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: inventorySettingsEventStatus = [] } = useInventorySettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: inventorySettingsDocumentType = [] } = useInventorySettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: inventorySettingsVendorType = [] } = useInventorySettings(SETTING_TYPE.VENDOR_TYPE);
  const [isOpenAddDocument, setOpenAddDocument] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (rawVendorDetails && rawVendorDetails.length) {
      setVendorDetails(rawVendorDetails[[0]]);
    }
  }, [rawVendorDetails]);

  const handleSave = async (data) => {
    await setInventoryVendors(data);
    setOpen(false);
  };

  const handleDelete = async () => {
    if (confirm('Are you sure to delete this vendor?')) {
      await deleteInventoryVendors(vendorDetails);
      history.push('/inventory/settings/vendors');
    }
  };

  const handleDeleteAssiciatedInstrument = async (instrumentDetails) => {
    const remainingVendors = (instrumentDetails.vendors || []).filter(
      (item) => item !== vendorDetails.id
    );
    if (confirm('Are you sure to delete this instrument?')) {
      await setInventoryEquipments({ ...instrumentDetails, vendors: remainingVendors });
    }
  };

  const handleDeleteAssiciatedEvent = async (eventDetails) => {
    const remainingVendors = (eventDetails.vendors || []).filter(
      (item) => item !== vendorDetails.id
    );
    if (confirm('Are you sure to delete this event?')) {
      await setInventoryEvents({ ...eventDetails, vendors: remainingVendors });
    }
  };

  const handleDeleteDocument = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      if (data.attachment && data.attachment.fullPath) {
        await deleteFileAsync(data.attachment.fullPath);
      }
      await deleteInventoryDocuments(data);
    }
  };

  const handleAddDocuments = async (data) => {
    const { attachment, ...document } = { ...data, vendorId: id };

    await setInventoryDocuments(document);

    // Is file object => upload
    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `inventory-management/${id}/${document.id}/${attachment[0].name}`,
        document
      );
    } else {
      document.attachment = attachment;
    }

    await setInventoryDocuments(document);

    setOpenAddDocument(false);
  };

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpenAddDocument(true);
  };

  const vendorType = inventorySettingsVendorType.find(
    (type) => type.id === vendorDetails?.vendorType
  );

  return (
    <div className="event-details">
      <div className="event-header">
        <h2></h2>
        <div className="actions">
          <Button variant="contained" onClick={() => setOpen(true)}>
            Edit
          </Button>
          <ModalFormVendor
            show={isOpen}
            data={vendorDetails}
            handleClose={() => setOpen(false)}
            handleSave={handleSave}
            inventorySettingsVendorType={inventorySettingsVendorType}
          />
          <Button variant="contained" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
      <div className="event-information">
        <h3>Vendor Information</h3>
        <table>
          <tbody>
            <tr>
              <td>Vendor ID</td>
              <td>{vendorDetails?.vendorId}</td>
              <td>Primary contact</td>
              <td>{vendorDetails?.contactName}</td>
            </tr>
            <tr>
              <td>Company</td>
              <td>{vendorDetails?.companyName}</td>
              <td>Primary Email</td>
              <td>{vendorDetails?.contactEmail}</td>
            </tr>
            <tr>
              <td>Department</td>
              <td>{vendorDetails?.department}</td>
              <td>Primary Phone</td>
              <td>{vendorDetails?.contactPhone}</td>
            </tr>
            <tr>
              <td>Vendor Type</td>
              <td>{vendorType?.name}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{VendorStatus[vendorDetails?.status]}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <h3>Notes</h3>
        <div>
          <Form.Group className="mb-3">
            <Form.Control as="textarea" rows={3} value={vendorDetails?.notes} readOnly={true} />
          </Form.Group>
        </div>

        <h3>Associated instruments</h3>
        <div className="table-box">
          <CustomTable
            data={instruments}
            header={assiciatedInstrumentColumns({
              instrumentStatus: inventorySettingsInstrumentStatus
            })}
            deleteRowData={handleDeleteAssiciatedInstrument}
            numberOfRows={10}
            action={['delete']}
          />
        </div>

        <h3>Associated events</h3>
        <div className="table-box">
          <CustomTable
            data={events}
            header={assiciatedEventColumns({
              instruments: allInstruments,
              eventStatus: inventorySettingsEventStatus
            })}
            deleteRowData={handleDeleteAssiciatedEvent}
            numberOfRows={10}
            action={['delete']}
          />
        </div>

        <div className="button-below text-center">
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
