import React from 'react';
import VendorList from './VendorList';
import { ListItemButton } from '@mui/material';
import { Link, Route, Switch, useLocation, useRouteMatch } from '../../../util/router';
import VendorDetails from './VendorDetails';
import DocumentList from './DocumentList';
import ReassignEvents from './ReassignEvents';
import ImportInstruments from './ImportInstruments';
import DefineCategories from './DefineCategories';
import NotificationGroups from './NotificationGroups';

const menuSidebar = [
  {
    url: 'vendors',
    text: 'View all vendors'
  },
  {
    url: 'documents',
    text: 'View all documents'
  },
  {
    url: 'reassign-event',
    text: 'Re-assign events'
  },
  {
    url: 'import-instrument',
    text: 'Import instruments'
  },
  {
    url: 'notification-groups',
    text: 'Notification Groups'
  },
  {
    url: 'define-categories',
    text: 'Define Categories'
  }
];

const TabSettings = () => {
  const { pathname } = useLocation();
  let { path, url } = useRouteMatch();

  return (
    <div className="settings-wrapper">
      <div className="settings-sidebar">
        {menuSidebar.map((item) => (
          <ListItemButton
            key={item.url}
            component={Link}
            to={`${url}/${item.url}`}
            selected={pathname.includes(item.url) ? true : false}
          >
            {item.text}
          </ListItemButton>
        ))}
      </div>
      <div className="settings-content">
        <Switch>
          <Route path={`${path}/vendors/create`} component={VendorDetails} />
          <Route path={`${path}/vendors/:id`} component={VendorDetails} />
          <Route path={`${path}/vendors`} component={VendorList} />

          <Route path={`${path}/documents`} component={DocumentList} />
          <Route path={`${path}/reassign-event`} component={ReassignEvents} />
          <Route path={`${path}/import-instrument`} component={ImportInstruments} />
          <Route path={`${path}/define-categories`} component={DefineCategories} />
          <Route path={`${path}/notification-groups`} component={NotificationGroups} />
        </Switch>
      </div>
    </div>
  );
};

export default TabSettings;
