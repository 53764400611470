import { Button } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { renderMUILoadingButton } from '../../../util/util';
const ModalFormInstrument = ({
  show,
  handleClose,
  handleSave,
  data,
  allLocations,
  inventorySettingsInstrumentStatus
}) => {
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      id: data?.id || '',
      name: data?.name || '',
      parameter: data?.parameter || '',
      location: data?.location || '',
      manufacturer: data?.manufacturer || '',
      serialNumber: data?.serialNumber || '',
      model: data?.model || '',
      status: data?.status || '',
      notes: data?.notes || '',
      internalId: data?.internalId || '',
      cost: data?.cost || ''
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      name: data?.name || '',
      parameter: data?.parameter || '',
      location: data?.location || '',
      manufacturer: data?.manufacturer || '',
      serialNumber: data?.serialNumber || '',
      model: data?.model || '',
      status: data?.status || '',
      notes: data?.notes || '',
      internalId: data?.internalId || '',
      cost: data?.cost || ''
    });
  }, [show]);

  const onSubmit = async (data) => {
    setLoading(true);
    await handleSave(data);
    setLoading(false);
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? 'Edit Instrument' : 'Add Instrument'}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Location</Form.Label>
                <Form.Select name="location" {...register('location', { required: true })}>
                  <option value="">-- Select --</option>
                  {allLocations.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Name</Form.Label>
                <Form.Control name="name" {...register('name', { required: true })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Parameter</Form.Label>
                <Form.Control name="name" {...register('parameter', { required: false })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Status</Form.Label>
                <Form.Select name="status" {...register('status', { required: true })}>
                  <option value="">-- Select --</option>
                  {inventorySettingsInstrumentStatus.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Model</Form.Label>
                <Form.Control name="model" {...register('model', { required: true })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Serial Number</Form.Label>
                <Form.Control
                  name="serialNumber"
                  {...register('serialNumber', { required: true })}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Manufacturer</Form.Label>
                <Form.Control
                  name="manufacturer"
                  {...register('manufacturer', { required: true })}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Internal ID</Form.Label>
                <Form.Control name="internalId" {...register('internalId', { required: true })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Cost</Form.Label>
                <Form.Control
                  name="cost"
                  type="number"
                  {...register('cost', { valueAsNumber: true })}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notes"
                  {...register('notes', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit'
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormInstrument;
